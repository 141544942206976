
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'ProductCounter',
  data() {
    return {
      qty: 1,
    }
  },
  mounted() {
    if (this.product?.quantity) {
      this.qty = this.product.quantity
    }
  },
  computed: {
    ...mapState({
      data: state => state.product.data,
      choices: state => state.product.choices,
      product: state => state.product
    }),
    isMobile() {
      return this.$mq == 'sm' ? true : false
    },
  },
  methods: {
    ...mapActions({
      updateProduct: 'product/updateProduct',
      removeProduct: 'product/removeProduct',
    }),
    ...mapMutations({
      setQuantity: 'product/setQuantity'
    }),
    handleDecrease(p) {
      if (this.qty > 1) {
        this.decrease(p)
      } else {
        this.$router.replace({ path: this.$router.currentRoute.path });
        this.$router.back();
      }
    },
    async decrease(p) {
      if(p.quantity > 1) {
        const updatedProd = {
          id: p.id,
          quantity: p.quantity - 1,
          choices: []
        }
        p.choices.map(c => {
          updatedProd.choices.push({
            id: c.option_id,
            quantity: 1
          })
        })
        await this.updateProduct(updatedProd)
      } else {
        if(this.qty > 1) {
          this.qty += -1
          this.setQuantity(this.qty)
        }
      }
    },
    async increase(p) {
      if(p.quantity < 99) {
        const updatedProd = {
          id: p.id,
          quantity: p.quantity + 1,
          choices: []
        }
        p.choices.map(c => {
          updatedProd.choices.push({
            id: c.option_id,
            quantity: 1
          })
        })
        await this.updateProduct(updatedProd)
      } else {
        if(this.qty < 99) {
          this.qty += 1
          this.setQuantity(this.qty)
        }
      }
    },
  },
}
